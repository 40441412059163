import image from "../image/dds.jpg";
function AboutMe() {
  const handleMouseMove = (e: React.MouseEvent) => {
    const pupils = document.querySelectorAll<HTMLElement>(".pupille");
    pupils.forEach((pupil) => {
      const rect = pupil.getBoundingClientRect();
      const x = (e.pageX - rect.left) / 10 + "px";
      const y = (e.pageY - rect.top) / 10 + "px";
      pupil.style.transform = `translate3d(${x},${y},0px)`;
    });
  };
  return (
    <section
      id="AboutMe"
      className="eye min-h-screen flex flex-col justify-center items-center pt-10 pb-10"
    >
      <div className="flex sm:flex-col gap-10">
        <div className="flex-1 flex h-full justify-center items-center">
          <div className=" liquid pupil blue">&nbsp;</div>
          <h1 className="text-6xl font-semibold text--effect absolute text-center text-white sm:text-5xl">
            About Me
          </h1>
        </div>
        <div className="flex-1 flex items-center justify-center">
          <h1 className="text-lg sm:text-xl font-semibold  text-center ">
            Dynamic Junior Software Engineer at Kaseya, adept at crafting
            efficient REST APIs with Laravel and optimizing SQL performance.
            Proficient in React, Typescript, and agile methodologies, bringing a
            robust skill set to the team. Holds a Bachelor of Science (Honours)
            degree in Computing (Information Technology) from Technological
            University Dublin.
          </h1>
        </div>
        <div className="flex-1 flex justify-center items-center">
          <div className="aspect-square rounded-full overflow-hidden border-zinc-800 border-4">
            <img src={image} />
          </div>
        </div>
      </div>
    </section>
  );
}
export default AboutMe;
