import { Link } from "react-router-dom";

export default function NavBar() {
  const navItems = ["About", "Links", "Skills", "Contact Me"];
  const links = ["AboutMe", "links", "skills", "ContactMe"];
  return (
    <nav className="px-[5%] flex flex-row justify-between mt-3 sm:hidden">
      {navItems.map((item, index) => (
        <div>
          <a className="font-bold" href={"#" + links[index]} key={index}>
            {item}
          </a>
        </div>
      ))}
    </nav>
  );
}
