function Description() {
  return (
    <section className=" flex flex-col justify-center items-center rounded-2xl h-full bg-black">
      <div className="text--bull relative Z-2">
        <div className="liquid pupil blue ">&nbsp;</div>
      </div>
      <div className=" absolute z-1 text-center">
        <h1 className=" text-4xl my-4 font-semibold text--effect--on text-black sm:text-xl">
          Expert in REST API design.
        </h1>
        <h1 className=" text-4xl my-4 font-semibold text--effect--on text-black sm:text-xl">
          Proficient in software development.
        </h1>
        <h1 className=" text-4xl my-4 font-semibold text--effect--on text-black sm:text-xl">
          Collaborative team player.
        </h1>
      </div>
    </section>
  );
}
export default Description;
