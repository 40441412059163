import NavBar from "./NavBar";
function Hero() {
  const handleMouseMove = (e: React.MouseEvent) => {
    const pupils = document.querySelectorAll<HTMLElement>(".mover");
    pupils.forEach((pupil) => {
      const rect = pupil.getBoundingClientRect();
      const x = (e.pageX - rect.left) / 10 + "px";
      const y = (e.pageY - rect.top) / 10 + "px";
      pupil.style.transform = `translate3d(${x},${y},0px)`;
    });
  };

  return (
    <section
      className="eye min-h-screen flex flex-col justify-center"
      onMouseMove={handleMouseMove}
    >
      <div className="flex flex-row justify-between gap-10 sm:flex-col">
        <div className="flex-1 flex items-center justify-center flex-col">
          <div className="flex flex-col ">
            <h1 className="sm:text-xl text-5xl font-semibold text--effect inline-block">
              Hi,
            </h1>
            <h1 className="sm:text-xl text-5xl font-semibold text--effect inline-block">
              I am Emmanuel Keka
            </h1>
            <h1 className=" text-9xl font-semibold text--effect inline-block"></h1>
            <div className="col-span-2 col-start-1 row-start-3 sm:text-xs">
              <p className="font-bold text-lg sm:text-xs inline-block">
                Welcome! Explore my portfolio showcasing my skills and
                dedication. Thanks for visiting!
              </p>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="flex flex-col justify-center blue">
            <div className="liquid pupil mover w-full">&nbsp;</div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
