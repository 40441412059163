import "./App.css";
import Hero from "./components/Hero";
import Description from "./components/Description";
import Cover from "./components/Cover";
import NavBar from "./components/NavBar";
import AboutMe from "./components/AboutMe";
import Link from "./components/Link";
import Skills from "./components/Skills";
import Contact from "./components/Contact";

function App() {
  return (
    <div className="App">
      <NavBar />
      <Hero />
      <Description />
      <AboutMe />
      <Link />
      <Skills />
      <Contact />
      <Cover />
    </div>
  );
}

export default App;
