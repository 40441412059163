import styled from "@emotion/styled";

export default styled.div`
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(https://grainy-gradients.vercel.app/noise.svg);
  pointer-events: none;
  z-index: 100;
  &:hover {
    pointer-events: none;
  }
`;
