import React, { useState } from "react";

export default () => {
  const services = [
    {
      title: "Web Frameworks",
      description:
        "Proficient in React for building dynamic user interfaces, Django for robust backend development, Laravel for efficient and secure web applications, and Spring Boot for scalable Java-based services. Skilled in leveraging these frameworks to create seamless and responsive web applications.",
    },
    {
      title: "Problem Solving",
      description:
        "Adept at analyzing complex issues and implementing effective solutions. Experienced in debugging, optimizing code, and employing algorithms to resolve technical challenges efficiently. Strong analytical skills enable innovative problem-solving in fast-paced development environments.",
    },
    {
      title: "Debugging",
      description:
        "Skilled in identifying and resolving software issues quickly and efficiently. Proficient in using debugging tools and techniques to troubleshoot and optimize code. Strong attention to detail ensures robust and reliable application performance.",
    },
    {
      title: "Linux",
      description:
        "Experienced in using Linux for development and system administration tasks. Proficient in shell scripting, command-line tools, and managing server environments. Capable of configuring, maintaining, and troubleshooting Linux systems for optimal performance and security.",
    },
  ];
  const [serive, setService] = useState(0);
  const changeServiceRight = () => {
    if (serive === services.length - 1) {
      setService(0);
      return;
    }
    setService(serive + 1);
  };
  const changeServiceLeft = () => {
    if (serive === 0) {
      setService(services.length - 1);
      return;
    }
    setService(serive - 1);
  };
  return (
    <section id="skills" className="eye min-h-screen flex flex-col justify-center items-center">
      <div className=" flex justify-center items-center flex-col">
        <h1 className="text-6xl font-semibold text--effect text-center text-black mt-8 sm:text-3xl ">
          {services[serive].title}
        </h1>
        <p className=" max-w-96 text-center my-10 sm:text-xs">
          {services[serive].description}
        </p>
      </div>
      <div className="flex flex-row justify-between gap-10 text--bull">
        <div className=" flex justify-center items-center flex-1">
          <div
            className="liquid22 justify-center items-center flex min-w-20"
            onClick={changeServiceLeft}
          >
            &nbsp;
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            className="w-6 z-10 absolute"
          >
            <path
              fill="#ffffff"
              d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
            />
          </svg>
        </div>
        <div className=" flex justify-center items-center flex-1">
          <div className="liquid22 bg-black" onClick={changeServiceRight}>
            &nbsp;
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            className="w-6 z-10 absolute"
          >
            {" "}
            <path
              fill="#ffffff"
              d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"
            />
          </svg>
        </div>
      </div>
    </section>
  );
};
